<template>
    <div>
    <div class="grid"  v-if="!loading">
      <div class="col-12" >
        <div class="card" >
          <Toast />
          <Toolbar class="mb-4">
            <template v-slot:start>
              <div class="my-1">
                <div class="col-12 section_title">Nuevo remito a emitir</div>
              </div>
            </template>
  
            <template v-slot:end>
              <Button label="Despachar" icon="pi pi-truck" class="p-button-success mr-2" @click="save()" />
            </template>
          </Toolbar>
  
          <ConfirmDialog></ConfirmDialog>
          <div class="">
            <div class="formgrid grid">
              <div class="field col-2"  >
                <label for="formu">N° de Remito </label>
                <InputText  v-model="form.nro_remito" id="formu" type="text"
                  class="inputfield w-full" placeholder="" />
              </div>

  
              <div class="field col-3">
                <label for="base">Cliente</label>
                <AutoComplete
                  v-model="selectedClient"
                  :suggestions="filteredClients"
                  @complete="searchClient($event)"
                  field="name"
                  :dropdown="true"
                  @item-select="getItems()"
                  id="base"
                  class="inputfield w-full"
                />
              </div>
              <div class="field col-3" v-if="selectedBudget">
                <label for="base">CUIT</label>
                <div>
  
                  {{ selectedBudget.client.cuit }}
                </div>
  
              </div>
              <div class="field col-4" >
 
  
              </div>
 
              <div class="field col-4"  >
                <label for="formu">Transportista</label>
                <InputText  v-model="form.transportista" id="formu" type="text"
                  class="inputfield w-full" placeholder="" />
              </div>
              <div class="field col-4"  >
                <label for="formu">Domicilio</label>
                <InputText  v-model="form.dom_transportista" id="formu" type="text"
                  class="inputfield w-full" placeholder="" />
              </div>
              <div class="field col-4"  >
                <label for="formu">CUIT Transportista</label>
                <InputText  v-model="form.cuit_transportista" id="formu" type="text"
                  class="inputfield w-full" placeholder="" />
              </div>
 
  
            </div>
          </div>
  
        </div>
      </div>
      <div class="col-12">
  
  
  
        <div class="card">
          <div class="my-1">
            <div class="col-12 section_subtitle">Items disponibles para entregar<small> </small></div>
          </div>
          <div class="">
            <!-- <pre>

              {{ items }}
            </pre> -->
            
            
            <DataTable :value="items" dataKey="id" class="p-datatable-sm" responsiveLayout="scroll">
  
              <Column header="">
                <template #body="slotProps">

                  <Checkbox v-model="slotProps.data.selectedToSend" :binary="true"  ></Checkbox>

                </template>
              </Column>
  
              <Column headerClass="" field="qty" header="Cantidad" headerStyle="max-width: 20%">
                <template #body="slotProps">
                  <InputNumber v-model="slotProps.data.quantity" id="iqty" type="text" class="inputfield w-full"
                    placeholder=""  :min="1" />
                </template>
              </Column>
              <Column field="piece_code" header="Código">
                <template #body="slotProps">
                  <b>
  
                    {{ slotProps.data.pieza.code }}
                  </b>
                </template>
              </Column>
              <Column field="piece_name" header="Detalle">
                <template #body="slotProps">
                  {{ slotProps.data.piece_name }}
                </template>
              </Column>
  
              <Column field="material_id" header="Material utilizado">
                <template #body="slotProps">
                  {{ slotProps.data.material }}
                </template>
  
              </Column>
              <Column header="OC">
                <template #body="slotProps">
                  {{ slotProps.data.op.oc_number }} 
                </template>
  
              </Column>
              <Column header="ITEM">
                <template #body="slotProps">
                  {{ slotProps.data.oc_item_nro }} 
                </template>
  
              </Column>
              <Column header="OT">
                <template #body="slotProps">
                  {{ slotProps.data.id }} 
                </template>
  
              </Column>
              
            </DataTable>
  
          </div>
        </div>
  
  
      </div>
  
  
    </div>
    <div v-else>
      Espere porfavor...
    </div>
  </div>
  </template>
    
  <script>
  import CrudService from "./../../services/crud.service.js";
  
  
  export default {
    data() {
      return {
        ocNumber: "",
        monedas: [{ value: 0, name: 'AR$' }, { value: 1, name: 'U$D' }],
        fac_types: [{ value: 1, name: 'A' }, { value: 6, name: 'B' }, { value: 11, name: 'C' }],
        impuestos: [{ value: 0, name: '0%' }, { value: 0.21, name: '21%' }, { value: 0.105, name: '10.5%' }],
        routeName: null,
        clientType: { value: 0, name: 'Consumidor Final' },
        configuraciones: [],
        qtyToAdd: 1,
        valorToAdd: 1,
        selectedFdp: null,
        form: {
          tipo: { "value": 1, "name": "A" },
  
          impuesto: { value: 0.21, name: '21%' },
          selectedFdp: "Contado", 
          selectedConcept: "Productos y Servicios",
          moneda: { value: 0, name: 'AR$' },
          observacion: ' PRUEBA ',
          cuit_transportista: '123',
          transportista: 'Andreani',
          dom_transportista: 'Calle Falsa 123'

  
  
        },
        items: [],
  
        actualFormula: "",
        products: null,
        filteredProducts: null,
        newItem: null,
        selectedProduct: null,
  
        Clients: null,
        filteredClients: null,
        newItem: null,
        selectedClient: null,
  
        Budgets: null,
        filteredBudgets: null,
        selectedBudget: null,
  
        stock: null,
        filteredStock: null,
  
  
        mods: null,
        newItemMOD: null,
        filteredMod: null,
        selectedMod: null,
        actualFormulaMOD: "",
  
        modsMarco: null,
        newItemMODMarco: null,
        filteredModMarco: null,
        selectedModMarco: null,
        actualFormulaMODMarco: "",
  
        submitted: false,
        validationErrors: null,
  
        ciif: 0,
        mpi: 0,
        moi: 0,
        //calcQuantity: 0,
  
        formasdepago: [
        ],
        materials: [],
        p_dolar: 1,
        dolar_suggested: 1,
        loading: true,
  
      };
    },
    computed: {
      dolarValue() {
        switch (this.form.moneda.value) {
          case 1:
            return this.configuraciones.dolar_billete;
          case 3:
            return this.configuraciones.dolar_divisa;
          default:
            return this.configuraciones.dolar_billete;
        }
  
      },
      totalItems() {
        let total = 0;
  
        this.items.forEach((item) => {
          //console.log(item);
          //if(item.mpc){
          //  total += (Number(item.valor) - item.matCost) * item.qty;
          //}else{
          total += Number(item.valor) * item.qty;
          //}
        });
  
        return total;
      },
      totalMOD() {
  
        let total = 0;
  
        this.mod.forEach(item => {
          console.log(item)
          total += item.valor * item.qty;
        });
  
        return total;
  
      },
 
    },
    created() {
      // get route name
      this.routeName = this.$route.name;
    },
    mounted() {
      CrudService.getCRUD("api/clients").then(
        (data) => (this.clients = data)
      ).then(
        () => {
         this.loading = false;
        }
      );
   
    },
    
    methods: {
      getItems(){


        CrudService.getCRUD("api/fordispatch/" + this.selectedClient.id)
        .then(
          (data) => {
   
            this.items = data.items
  
  
          }
        ).then(
          () => {
            
              
 
  
          }
        ) 
      },
      changeMpc(item, index) {
  
        console.log(item)
  
  
        if (item.mpc) {
          this.items[index].valor = Number(item.valor) - Number(item.matCost);
  
        } else {
          this.items[index].valor = Number(item.valor) + Number(item.matCost);
        }
  
        this.items[index].valor = Number(this.items[index].valor).toFixed(2);
  
      },
      selectedItem(selectedProduct) {
        this.newItem = null;
        this.valorToAdd = selectedProduct.last_price;
        if (selectedProduct.formula) {
          this.mpnameToAdd = selectedProduct.formula.material_name;
        }
      },
      selectBudget(budget) {
        this.form.observacion = this.selectedBudget.obs;
  
        this.form.impuesto = this.impuestos.find(item => item.value == this.selectedBudget.impuesto);
        this.form.moneda = this.monedas.find(item => item.value == this.selectedBudget.moneda);
  
        this.form.envio = this.selectedBudget.envio;
  
        this.form.descuento = this.selectedBudget.descuento;
  
        let dateE = new Date(this.selectedBudget.fecha_entrega);
        this.form.fecha_entrega = this.selectedBudget.fecha_entrega //dateE.getDate() + "/" + (dateE.getMonth() + 1) + "/" + dateE.getFullYear();
  
  
        this.selectedClient = this.clients.find(item => item.id == this.selectedBudget.client_id);
  
        this.items = this.selectedBudget.items
  
        // if items has mpc set valor to matCost
        this.items.forEach((item) => {
          if (item.mpc) {
            item.valor = Number((item.valor - item.mpcost).toFixed(2));
  
          }
        });
  
  
  
      },
      valorByClientType(valor) {
        if (this.clientType.value == 0) {
          return valor
        } else {
          return valor * 0.3 + valor
        }
      },
  
      costoProd() {
  
        let costo = 0;
  
        return costo;
  
      },
      toArs(value) {
  
        return value.toLocaleString("es-AR", {
          style: "currency",
          currency: "ARS",
        });
      },
      toUsd(value) {
        value = value / this.p_dolar;
        // format number to currency
        return value.toLocaleString("es-AR", {
          style: "currency",
          currency: "USD",
        });
  
      },
      toSelectedValue(value) {
  
        if (this.form.moneda.value == 0) {
          return this.toArs(value.toFixed(2))
        } else {
          return this.toUsd(value.toFixed(2))
        }
  
      },
      showPlane(file) {
  
        // open window
        window.open(file, "_blank");
  
  
  
      },
      delItem(itemId) {
        //console.log(item)
        // find in this.items
        let index = this.items.findIndex(item => item.id == itemId);
        // remove item
        this.items.splice(index, 1);
  
        // recalculate total
  
  
      },
      formatUnity(value) {
  
        switch (value) {
          case 0:
            return "ml";
          case 1:
            return "unidad";
          case 2:
            return "kg";
          case 3:
            return "mt";
          case 4:
            return "lt";
          case 5:
            return "m2";
          case 6:
            return "mtl";
          case 7:
            return "gr";
          case 8:
            return "juego";
          default:
            return "";
        }
      },
      displayErrors(errors) {
        for (let key in errors) {
          this.validationErrors.push({ field: key, message: errors[key] });
  
          let error = errors[key];
          this.$toast.add({
            severity: "error",
            summary: "Error!",
            detail: error[0],
            life: 3000,
          });
        }
      },
      searchStock(event) {
        setTimeout(() => {
          if (!event.query.trim().length) {
            this.filteredStock = [...this.stock];
          } else {
            this.filteredStock = this.stock.filter((prod) => {
              return prod.name
                .toLowerCase()
                .startsWith(event.query.toLowerCase());
            });
          }
        }, 250);
      },
      searchProduct(event) {
        setTimeout(() => {
          if (!event.query.trim().length) {
            this.filteredProducts = [...this.products];
          } else {
            this.filteredProducts = this.products.filter((prod) => {
              return prod.name
                .toLowerCase()
                .startsWith(event.query.toLowerCase()) || prod.code.startsWith(event.query);
            });
          }
        }, 250);
      },
      searchClient(event) {
        setTimeout(() => {
          if (!event.query.trim().length) {
            this.filteredClients = [...this.clients];
          } else {
            this.filteredClients = this.clients.filter((prod) => {
              return prod.name
                .toLowerCase()
                .startsWith(event.query.toLowerCase());
            });
          }
        }, 250);
      },
  
      searchBudgets(event) {
        setTimeout(() => {
          if (!event.query.trim().length) {
            this.filteredBudgets = [...this.Budgets];
          } else {
            this.filteredBudgets = this.Budgets.filter((prod) => {
              return prod.id == event.query || prod.client_name.toLowerCase().includes(event.query.toLowerCase());
            });
          }
        }, 250);
      },
      searchMDO(event) {
        setTimeout(() => {
          if (!event.query.trim().length) {
            this.filteredMod = [...this.mods];
          } else {
            this.filteredMod = this.mods.filter((prod) => {
              return prod.name
                .toLowerCase()
                .startsWith(event.query.toLowerCase());
            });
          }
        }, 250);
      },
      addItem() {

        // check if this.items has selectedToSend value equal to true
        let selectedToSend = this.items.filter(item => item.selectedToSend == true);





        // check if selected product lenght
        // if (!this.selectedProduct.length) {
        //   this.items.push({
        //     pieza_id: this.selectedProduct.id,
        //     name: this.selectedProduct.name,
        //     qty: this.qtyToAdd,
        //     valor: this.valorToAdd,
        //     piece_code: this.selectedProduct.code,
        //     planos: this.selectedProduct.planos,
        //     matCost: this.selectedProduct.formula.materiales.reduce(
        //       (a, b) => a + Number(b.valor),
        //       0)
        //   });
        // } else {
  
        //   this.items.push({
        //     id: 0,
        //     name: this.newItem ? this.newItem : this.selectedProduct,
        //     qty: this.qtyToAdd,
        //     code: this.selectedProduct.code,
        //     valor: this.valorToAdd,
        //     matCost: this.selectedProduct.formula.materiales.reduce(
        //       (a, b) => a + Number(b.valor),
        //       0)
  
        //   });
  
        // }
  
        this.newItem = null;
        this.qtyToAdd = 1;
        this.valorToAdd = 1;
        this.selectedProduct = null;
  
      },
      save() {
        this.$confirm.require({
                  message: 'Estás seguro de generar el remito?',
                  header: 'Despachar',
                  icon: 'pi pi-exclamation-triangle',
                  accept: () => {
                      
                    
        this.submitted = true;
  
  
  let formData = new FormData();
 
  formData.append("client_id", this.selectedClient.id)
   

  // check this.items qty
  // let chkqty = 0;
  // this.items.forEach(item => {
  //   console.log(item)
  //   chkqty += item.qty
  // })

  // if(chkqty == 0){
  //   this.submitted = false;
  //   this.$toast.add({
  //             severity: "error",
  //             summary: 'No hay cantidades ingresadas para generar un remito.',
  //             life: 3000,
  //           });
  //   return;
  // }

  let selectedToSend = this.items.filter(item => item.selectedToSend == true);
  formData.append("items", JSON.stringify(selectedToSend));
  
  formData.append("transportista", this.form.transportista);
  formData.append("cuit_transportista", this.form.cuit_transportista);
  formData.append("dom_transportista", this.form.dom_transportista);

  formData.append("nro_remito", this.form.nro_remito);
 
  CrudService.createCRUD("api", "remitose", formData).then(
    (data) => {
      if (data.status == "success") {
  
        this.$toast.add({
          severity: "success",
          summary: "Éxito!",
          detail: "Creada correctamente.",
          life: 3000,
        });
  
        // open new window with pdf
        window.open('http://127.0.0.1:8000/show_rem/' + data.data.id + '.pdf', "_blank");
  
  
        this.$router.push("/entregas");
  
      } else {
  
        for (var key in data.data.errors) {
          if (data.data.errors.hasOwnProperty(key)) {
            this.$toast.add({
              severity: "error",
              summary: data.data.errors[key][0],
              life: 3000,
            });
          }
        }
  
  
      }
  
  
      // }
    }
  );
  
  
  
  
                  },
                  reject: () => {
                      this.$toast.add({severity:'error', summary:'Rejected', detail:'You have rejected', life: 3000});
                  }
      });
  
  
      },
    },
  };
  </script>
    
    
  <style scoped>
  .section_subtitle {
    font-size: 1.2rem;
    font-weight: bold;
    color: #1261b1;
    margin-bottom: 1rem;
  }
  
  .card {
    padding-top: 0.25rem;
  }
  </style>